import React, { Component } from "react"

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = { name: "", email: "", message: "" };
    }

    componentDidMount() {
        document.querySelector('.form').append(document.querySelector('.form__fields'));
    }

    handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...this.state })
        })
        .then(() => document.querySelector('.success__message').classList.add('show'))
        .catch(error => alert(error));

        e.preventDefault();
    };

    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    render() {
        const { name, email, lessonType, message } = this.state;
        
        return (
        <form className="form__fields" onSubmit={this.handleSubmit} data-netlify="true" data-netlify-honeypot="bot-field" name="contact">
            <div className="form__field">
                <label htmlFor="name">
                    Your Name: <input type="text" name="name" id="name" value={name} onChange={this.handleChange} />
                </label>
            </div>
            <div className="form__field">
                <label htmlFor="email">
                    Your Email: <input type="email" name="email" id="email" value={email} onChange={this.handleChange} />
                </label>
            </div>
            <div className="form__field">
                <label htmlFor="lesson-type">Please select the lesson you're most interested in:</label>
                <div className="select-container">
                    <select id="lesson-type" name="lessonType" value={lessonType} onBlur={this.handleChange}>
                        <option value="No selection">--Please select--</option>
                        <optgroup label="Music">
                            <option value="Guitar">Guitar</option>
                            <option value="Drums">Drums</option>
                            <option value="Singing">Singing</option>
                            <option value="Piano">Piano</option>
                            <option value="Bass">Bass</option>
                            <option value="Ukelele">Ukelele</option>
                            <option value="Music Theory">Music Theory</option>
                            <option value="Bands">Bands</option>
                        </optgroup>
                        <optgroup label="Dance">
                            <option value="Musical Theatre">Musical Theatre</option>
                            <option value="LAMDA Preparation">LAMDA Preparation</option>
                            <option value="Balllet">Ballet</option>
                            <option value="Jazz">Jazz</option>
                            <option value="Commercial Pop">Commercial Pop</option>
                            <option value="Dance for Musical Theatre">Dance for Musical Theatre</option>
                            <option value="Fance for Fitness">Dance For Fitness</option>
                            <option value="Shakespeare Study">Shakespeare Study</option>
                        </optgroup>
                        <optgroup label="Early Years">
                            <option value="Baby Bears Ballet">Baby Bears Ballet</option>
                            <option value="Music and Movement" dangerouslySetInnerHTML={{__html: "Music &amp; Movement"}} />
                            <option value="Reading Corner">Reading Corner</option>
                            <option value="Arts and Crafts" dangerouslySetInnerHTML={{__html: "Arts &amp; Crafts"}} />
                        </optgroup>
                    </select>
                </div>
            </div>
            <div className="form__field">
                <label htmlFor="message">
                    Message: <textarea name="message" id="message" value={message} onChange={this.handleChange} />
                </label>
            </div>
            <div className="success__message">
                <p>Thank you for your enquiry. We will get back to you very shortly.</p>
            </div>
            <div className="form__field submit">
                <button type="submit" className="button">Send</button>
            </div>

            <input type="hidden" name="form-name" value="contact" />
        </form>
        );
    }
}

export default ContactForm;