import React, { Component } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import DefaultLayout from "../layout"
import ContactForm from "../components/contact-form"
import Img from "gatsby-image/withIEPolyfill"
import "../assets/styles/fonts.css"
import "../assets/styles/components/page-modules.css"

class Home extends Component {
  componentDidMount() {
    for (var i = 0; i < document.getElementsByClassName('wp-block-media-text').length; i += 1) {
        document.getElementsByClassName('wp-block-media-text')[i].setAttribute("tabIndex", "0")
    }
  }

  render() {
    const data = this.props.data.allWordpressPage.edges,
          yoastData = data[0].node.yoast;

    return (
      <DefaultLayout className="">
        <SEO title={yoastData.title} description={yoastData.metadesc} />
          {data.map(({ node }) => (
            node.menu_order < 99 &&
            <div id={node.slug} key={node.id} className="section">
              {node.featured_media !== null && <div className="background__image"><Img fluid={node.featured_media.localFile.childImageSharp.fluid} fadeIn={true} loading="eager" objectFit="cover" /></div>}
              <div className="wrapper">
                <h1 className="page-title" dangerouslySetInnerHTML={{__html: node.title}} />
                <div className="entry-content" dangerouslySetInnerHTML={{ __html: node.content }} />
              </div>
            </div>
          ))}
        <ContactForm />
      </DefaultLayout>
    )
  }
}
export default Home

// Set here the ID of the home page.
export const pageQuery = graphql`
  {
    allWordpressPage(sort: {order: ASC, fields: menu_order}) {
      edges {
        node {
          title
          id
          content
          slug
          menu_order
          yoast {
            canonical
            title
            twitter_description
            twitter_image
            twitter_title
            redirect
            opengraph_title
            opengraph_image
            metakeywords
            opengraph_description
            metadesc
            meta_robots_noindex
            meta_robots_nofollow
            meta_robots_adv
            linkdex
            focuskw
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(toFormatBase64: WEBP) {
                  srcWebp
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }`